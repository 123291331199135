footer {
  position: absolute;
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  footer {
    position: absolute;
    z-index: 1;
    bottom: 0;
    opacity: 0.7;
    left: 50%;
    background-color: var(--footerPrimary);
    font-size: 15px;
    padding: 0 10px;
  }
}


