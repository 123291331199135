@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.omnibox {
  position: absolute;
  left: 0;
  top: 0;

  max-width: 392px;
  width: calc(100% - 16px);
  height: 48px;
  margin: 8px;
  border-radius: 8px;

  background-color: var(--omniboxPrimary);
  box-shadow: 0 2px 4px var(--transparentBlack), 0 -1px 0px var(--transparentBlack);

  z-index: 2;
}

.omnibox form {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 100%;
}

.omnibox form input {
  flex: 1 1;

  min-width: 0;
  padding: 16px 8px;

  font-size: 15px;
  font-weight: 500;
  color: var(--inputPrimary);

  border: none;
  outline: none;
  background: none;
  font-family: inherit;
  text-overflow: ellipsis;
}

.omnibox form input::-webkit-input-placeholder {
  color: var(--gray);
}

.omnibox form input:-ms-input-placeholder {
  color: var(--gray);
}

.omnibox form input::-ms-input-placeholder {
  color: var(--gray);
}

.omnibox form input::placeholder {
  color: var(--gray);
}

.omnibox form .icon {
  flex: 0 0 24px;

  width: 24px;
  height: 24px;

  padding: 12px 15px;

  color: var(--gray);
  cursor: pointer;
}

.omnibox form .icon:hover,
.omnibox form .icon.active {
  color: var(--blue);
}

.omnibox form .icon.logo {
  color: var(--logo);
}

.omnibox form .divider {
  width: 1px;
  height: 24px;

  background-color: var(--lightGrayPrimary);
}

.video {
  display: flex;
  flex-direction: column;

  padding: 24px;

  border-bottom: 1px solid var(--lightGraySecondary);
}

.video a {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: 400;
  text-decoration: none;
}

.video a:hover {
  text-decoration: underline;
}

.video a img {
  width: 32px;
  height: 32px;

  margin-right: 12px;

  border-radius: 16px;
}

.video a .source {
  flex: 1 1;
}

.video a .source .label {
  color: var(--black);
  font-size: 15px;
}

.video a .source .type {
  color: var(--mediumGray);
  font-size: 14px;
}

.video > p {
  margin: 12px 0 0;

  color: var(--black);
  font-size: 14px;
}

.video video {
  height: 256px;
  margin-top: 12px;

  object-fit: cover;
}

.evidence {
  display: flex;
  flex-direction: column;
}

.evidence > h4 {
  margin: 24px 0 0 0;
  padding: 0 24px;

  color: var(--evidenceTitle);
  font-size: 16px;
  font-weight: 500;
}

.evidence > p {
  margin: 12px 0;
  padding: 0 24px;

  color: var(--evidenceDescription);
  font-size: 13px;
}
.links {
  display: flex;
  flex-wrap: wrap;

  padding: 12px 8px 0 8px;
}

.links a {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1 1;
  margin-bottom: 12px;

  cursor: pointer;
  text-decoration: none;
}

.links a svg {
  width: 18px;
  height: 18px;

  padding: 8px;

  color: var(--blue);
  border-radius: 17px;
  border: 1px solid var(--blue);
}

.links a:hover svg {
  background-color: var(--iconHoverBackground);
}

.links a span {
  margin-top: 4px;

  font-size: 13px;
  font-weight: 400;

  color: var(--blue);
}

.result-details {
  flex: 1 1;
  
  overflow-y: auto;
}

.result-details .header {
  display: flex;
  align-items: center;

  padding: 12px 18px 16px 0;
}

.result-details .header svg {
  width: 24px;
  height: 24px;

  padding: 24px;

  color: var(--arrowLeftIcon);
  cursor: pointer;
}

.result-details .header svg:hover {
  color: var(--arrowLeftIconHover);
}

.result-details .header h3 {
  margin: 0;

  color: var(--resultTitle);
  font-size: 22px;
  font-weight: 500;
}

.result-details .header p {
  margin: 0;
  padding-top: 8px;

  color: var(--resultDate);
  font-size: 14px;
}

.result-details .divider {
  height: 1px;
  background-color: var(--resultDetailsDivider);
}

.results-details-loader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.search-result {
  display: flex;
  flex-direction: column;

  min-height: 112px;
  padding: 10px 18px 10px 24px;

  cursor: pointer;
  border-bottom: 1px solid var(--lightGraySecondary);
}

.search-result:hover {
  background-color: var(--searchResultHover);
}

.search-results {
  overflow-y: auto;
  
  background-color: var(--searchResultsBackground);
  border-top: 1px solid var(--lightGraySecondary);
}

.search-results.empty {
  padding: 18px 24px;
}

.search-results h4 {
  margin: 0;
  
  color: var(--searchResultText);
  font-size: 16px;
  font-weight: 500;
}

.search-results p {
  margin: 4px 0;

  color: var(--searchResultDetailsText);
  font-size: 13px;
}

.search-results p.call-to-action {
  margin: 18px 0 0;
}

.search-results a {
  color: var(--blue);
  font-size: 13px;
  text-decoration: none;
}

.search-results a:hover {
  text-decoration: underline;
}

.panel {
  display: flex;
  flex-direction: column;
  
  width: 408px;
  height: 100vh;

  background-color: var(--panelOpenBackground);
  box-shadow: 0 0 20px var(--transparentBlack);

  z-index: 1;
}

.panel:not(.open) {
  display: none;
}

.panel .omnibox-spacer {
  flex: 0 0 64px;
}

footer {
  position: absolute;
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  footer {
    position: absolute;
    z-index: 1;
    bottom: 0;
    opacity: 0.7;
    left: 50%;
    background-color: var(--footerPrimary);
    font-size: 15px;
    padding: 0 10px;
  }
}



.app {
  display: flex;
  flex-direction: row;

  font-family: 'Rubik', sans-serif;
}

:root {
    /* Colors */
    --black: #000000;
    --white: #FFFFFF;
    --offWhitePrimary: #FAFAFA;
    --offWhiteSecondary: #F1F3F4;
    --charcoalPrimary: #252525;
    --charcoalSecondary: #1B1B1B;
    --transparentBlack: rgba(0, 0, 0, 0.2);
    --gray: #BBBBBB;
    --mediumGray: #70757A;
    --lightGrayPrimary: #DDDDDD;
    --lightGraySecondary: #E6E6E6;
    --blue: #3771E0;
    --silver: #A7A7A7;

    /* Global */
    caret-color: auto;

    /* Footer */
    --footerPrimary: var(--lightGraySecondary);

    /* Omnibox */
    --omniboxPrimary: var(--white);
    --logo: var(--black);
    --inputPrimary: auto;

    /* Panel */
    --panelOpenBackground: var(--searchResultsBackground);
    
    /* Result Details */
    --resultTitle: var(--black);
    --resultDate: var(--silver);
    --resultDetailsDivider: var(--lightGraySecondary);
    --arrowLeftIcon: var(--gray);
    --arrowLeftIconHover: var(--black);

    /* Result Details - Evidence */
    --evidenceTitle: var(--black);
    --evidenceDescription: var(--mediumGray);

    /* Result Details - Links */
    --iconHoverBackground: var(--offWhiteSecondary);

    /* Search Results */
    --searchResultHover: var(--offWhitePrimary);
    --searchResultText: var(--black);
    --searchResultDetailsText: var(--mediumGray);
    --searchResultsBackground: var(--white);
}


[data-theme="dark"] {
    /* Colors */
    --black: #000000;
    --white: #FFFFFF;
    --offWhitePrimary: #FAFAFA;
    --offWhiteSecondary: #F1F3F4;
    --charcoalPrimary: #252525;
    --charcoalSecondary: #1B1B1B;
    --transparentBlack: rgba(0, 0, 0, 0.2);
    --gray: #BBBBBB;
    --mediumGray: #70757A;
    --lightGrayPrimary: #DDDDDD;
    --lightGraySecondary: #E6E6E6;
    --blue: #3771E0;
    --silver: #A7A7A7;
    
    /* Global */
    caret-color: #FFFFFF;
    caret-color: var(--white);

    /* Footer */
    --footerPrimary: var(--lightGraySecondary);

    /* Omnibox */
    --omniboxPrimary: var(--charcoalPrimary);
    --logo: var(--white);
    --inputPrimary: var(--white);
    
    /* Panel */
    --panelOpenBackground: var(--searchResultsBackground);

    /* Result Details */
    --resultTitle: var(--white);
    --resultDate: var(--silver);
    --resultDetailsDivider: var(--lightGraySecondary);
    --arrowLeftIcon: var(--white);
    --arrowLeftIconHover: var(--silver);

    /* Result Details - Evidence */
    --evidenceTitle: var(--white);
    --evidenceDescription: var(--silver);

    /* Result Details - Links */
    --iconHoverBackground: var(--charcoalPrimary);
    
    /* Search Results */
    --searchResultHover: var(--charcoalPrimary);
    --searchResultText: var(--white);
    --searchResultDetailsText: var(--silver);
    --searchResultsBackground: var(--charcoalSecondary);
}
