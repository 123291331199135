.search-result {
  display: flex;
  flex-direction: column;

  min-height: 112px;
  padding: 10px 18px 10px 24px;

  cursor: pointer;
  border-bottom: 1px solid var(--lightGraySecondary);
}

.search-result:hover {
  background-color: var(--searchResultHover);
}
